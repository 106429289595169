.results {
    ul {
        width: 50%;
        margin: 0 auto;
    }
    li {
        display: flex;

        > div {
            width: 50%;
        }
    }

    .cell-0 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .data-table {
        border-collapse: collapse;
        width: 100%;
        border: 1px solid #ddd;
      }
      
      .data-table th, .data-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
}