.header-nav {
  background: #fff;
  border-bottom-color: #fff;
  box-shadow: 0px 0px 15px rgba(21, 21, 21, 0.35);
  padding: 10px 20px;

  img {
    width: 70px;
    border-radius: 100%;
  }

  a {
    color: #56198b;
    font-weight: 600;
  }
}

.rotation-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate-element {
  background-color: #3498db;
  animation: rotate 5s linear infinite;
  /* Continuous rotation animation */
}