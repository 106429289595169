.Socialbanner {
    background-image: url(../../assets/images/counter-banner.jpg);
    height: 200px;
    color: #fff;   
    text-align: center;
    justify-content: center;
    margin-top: 20px;
}

.Socialbanner img {
    width: 50px;
    margin: 20px 0;
    cursor: pointer;
}