.category-list {
    li {
        padding: 5px 0;
    }

    a {
        text-transform: uppercase;
    }
}

.legend-grid {
    height: 400px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 0px 5px;
    }
}

.react-tabs__tab {
    padding: 10px 20px;

    &--selected {
        background: #fff;
        border-color: #aaa;
        color: black;
        border-radius: 0;
        background: #c720b1;
        color: #fff;
    }
}

.category-list {
    text-align: center;
}

.category-list p {
    padding: 10px 0px;
}