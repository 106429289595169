.scrolling-statistics-banner {
	background-image: url(../../assets/images/counter-banner.jpg);
	color: #fff;
	padding: 70px 0;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.counter-wrapper {
	height: 100%;
	align-items: center;
	display: grid;
	text-align: center;
}

.statistic {
	background: #57198b;
	padding: 20px 0;
	margin-top: 20px;
	border-radius: 12px;
}