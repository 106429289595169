.banner-header {
    background-image: url(../../assets/images/bannner2.jpg);
    height: 80vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        background: #000;
        height: 100%;
        width: 100%;
        content: '';
        left: 0;
        position: absolute;
        opacity: 0.8;
    }
}

.title-container.s-tb-c {
    text-align: center;
    color: #fff;
    margin: 0 auto;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.big-title {
    display: inline-block;
    padding: 15px 15px;
    font-weight: 700;
    // background: #cd935f;
    color: #ddc19b;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Cinzel Decorative", serif;
    font-style: normal;
}