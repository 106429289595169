.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  font-family: "Roboto", sans-serif;
}



@media screen and (min-width : 768px) {
  .promo-creative {
    max-height: 525px;
    overflow: hidden;
  }
}

.promo-creative img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about-us span {
  color: #c720b1;
  font-weight: 700;
}

.main-subtitle {
  display: inline-block;
  background: #c720b1;
  color: #fff;
  padding: 10px 20px;
  margin: 10px 0;
  text-transform: capitalize;
}

.bg-common-cream {
  background: #dea570;
}

.color-common-cream {
  background: #dea570;
}

ul {
  list-style: inside;
}


.grid-coreteam img {
  height: 330px;
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
}

a {
  word-wrap: break-word;
}