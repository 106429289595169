.progress-area {
    background-image: url(../../assets/images/counter-banner.jpg);
    height: 200px;
}

.timer {
    background: #fff;
    color: #000;
    background: #57198b;
    color: #ddc29b;
    padding: 10px;
    box-shadow: 5px 0px 2px 0px #57198b;
    border-radius: 10px;
}

.unit.text-black {
    color: #fff;
}